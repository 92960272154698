<template>
  <div>
    <a-page-header style="padding: 0" sub-title="Connect Miles">
      <div slot="title">
        <h1>RELATÓRIOS</h1>
      </div>
      <div slot="extra"></div>
    </a-page-header>

    <div class="panel mt-10">
      <div class="modules">
        <div
          v-for="(report, index) in reports"
          :key="index"
          class="module report"
        >
          <div class="card contratos" @click="$router.push(report.route)">
            <div class="ico" style="left: -15px; top: -35px; font-size: 128px">
              <i
                v-if="report.icon.includes('fawsome')"
                :class="report.icon"
              ></i>
              <a-icon
                v-if="!report.icon.includes('fawsome')"
                :type="report.icon"
              />
            </div>
            <div class="name">
              {{ report.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reports: [
        {
          name: "Milhas",
          route: "/miles-cards/reports/miles",
          icon: "fawsome fas fa-cubes",
        },
        {
          name: "Emissões",
          route: "/miles-cards/reports/emissions",
          icon: "fawsome fas fa-ticket-alt",
        },
        {
          name: "Bloqueios",
          route: "/miles-cards/reports/blocks",
          icon: "fawsome  fas fa-ban",
        },
        {
          name: "Compras",
          route: "/miles-cards/reports/purchases",
          icon: "shopping-cart",
        },
        // {
        //     name: "Check-in",
        //     route: "/miles-cards/reports/checkin",
        //     icon: "fawsome fas fa-plane",
        // },
        {
          name: "Taxas Cartão",
          route: "/miles-cards/reports/card-taxes",
          icon: "fawsome far fa-credit-card",
        },
        {
          name: "Reembolso",
          route: "/miles-cards/reports/refund",
          icon: "fawsome fas fa-exchange-alt",
        },
      ],
    };
  },
  beforeMount() {
    document.title = "Relatórios de Milhas - HAYA";
  },
};
</script>

<style lang="scss" scoped></style>
